<template>
  <div class="sure-pay-page">
    <van-cell-group class="order" inset>
      <van-cell class="title" title="订单信息" />
      <van-cell
        v-if="this.$route.query.code != 'H013'"
        :border="false"
        title="产品名称"
        :value="orderInfo.productName"
      />
      <van-cell :border="false" title="订单编号" :value="orderId" />
      <van-cell :border="false" title="下单日期" :value="orderInfo.orderTime" />
    </van-cell-group>

    <van-cell-group class="order" inset>
      <van-cell class="title" title="请选择支付方式" />
      <van-cell
        v-if="yunshow"
        class="pay"
        :border="false"
        title="产品名称"
        value="内容"
      >
        <template slot="title">
          <img :src="require(`@/assets/yunPay.jpg`)" alt="" />
        </template>
        <template slot="default"> 云闪付 </template>
        <template slot="extra">
          <van-checkbox v-model="checked"></van-checkbox>
        </template>
      </van-cell>
      <van-cell
        v-if="!yunshow"
        class="pay"
        :border="false"
        title="产品名称"
        value="内容"
      >
        <template slot="title">
          <img :src="require(`@/assets/lakala.jpg`)" alt="" />
        </template>
        <template slot="default"> 拉卡拉收银台 </template>
        <template slot="extra">
          <van-checkbox v-model="checkedtow"></van-checkbox>
        </template>
      </van-cell>
    </van-cell-group>

    <van-submit-bar
      :price="orderAmount"
      label="支付金额： "
      currency=" "
      suffix-label="元"
      text-align="left"
      button-text="确认支付"
      button-type="primary"
      :loading="loading"
      @submit="toPay"
    />
  </div>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import { GET_ORDER } from "@/store/action-types";
import { payAppTrans, lklAppTrans } from "@/api";

import options from "./options";

import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { Toast } from "vant";

const orderModule = namespace("order");
const orderAction = orderModule.Action;
const orderState = orderModule.State;
const orderGetter = orderModule.Getter;

@Component({
  ...options,
})
class SurePay extends Vue {
  checked = true;
  checkedtow = true;
  orderId = "";
  yunshow = true;
  nihao = false;
  @State("loading") loading;
  @orderAction(GET_ORDER) getOrder;
  @orderState("orderInfo") orderInfo;
  @orderGetter("orderAmount") orderAmount;

  async mounted() {
    const { query } = this.$route;
    this.orderId = query.orderId;
    await this.getOrder(query);
    // 记录可回溯信息
    window.GluttonContext = {
      orderNo: query.orderId,
      policyHolderName: this.orderInfo.policyHolderName,
    };
  }

  payCallback(msg, success = false) {
    this.$toast({
      message: msg,
      onClose: () => {
        this.$router.push({
          path: "/payResult",
          query: { success },
        });
      },
    });
  }
  created() {
    console.log(this.$route.query.code);
    if (
      this.$route.query.code === "H008" ||
      this.$route.query.code === "H009" ||
      this.$route.query.code === "H006" ||
      this.$route.query.code === "H010" ||
      this.$route.query.code === "H012" ||
      this.$route.query.code === "H013" ||
      this.$route.query.code === "H014" ||
      this.$route.query.code === "H016"
    ) {
      this.yunshow = false;
    }
  }
  toPay() {
    this.$Glutton.stopRecording();
    if (!this.checked || !this.checkedtow) {
      Toast.fail("请选择支付方式");
      return;
    }
    if (this.yunshow === true) {
      window.upsdk.pluginReady(async () => {
        console.log(this.orderInfo);
        const params = {
          money: this.orderInfo.totalAmount,
          orderId: this.orderId,
          planCode: this.orderInfo.planCode,
        };
        const { tn } = await payAppTrans(params);
        if (tn) {
          window.upsdk.pay({
            tn: tn,
            success: () => {
              this.payCallback("支付成功", true);
            },
            fail: (err) => {
              this.payCallback(err.message || "支付失败");
            },
          });
        }
      });
    }
    if (this.yunshow === false) {
      window.upsdk.pluginReady(async () => {
        const params = {
          orderNo: this.orderInfo.orderId,
          totalAmount: this.orderAmount * 0.1 * 10,
        };
        const { counter_url } = await lklAppTrans(params);
        if (counter_url) {
          window.location.href = counter_url;
          window.upsdk.pay({
            counter_url: counter_url,
            success: () => {
              this.payCallback("支付成功", true);
            },
            fail: (err) => {
              this.payCallback(err.message || "支付失败");
            },
          });
        }
      });
    }
  }
}
export default SurePay;
</script>

<style lang="scss" src="./index.scss" scoped></style>
