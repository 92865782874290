import { Cell, CellGroup, SubmitBar, Checkbox } from "vant";
import { registerComponent } from "@/utils";

export const components = registerComponent({
  Cell,
  CellGroup,
  SubmitBar,
  Checkbox,
});

export default { components };
